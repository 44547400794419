export default function Login(){





    return(




        <h1>this is login page</h1>

    )
}